import axios from "axios";
import { backendUrl } from "./config";

export const sendGetRestRequest = async function (api_ep) {
    try {
      console.log('Backend Url -->',backendUrl+api_ep);
    const token = window.sessionStorage.getItem("token");
      const response = await axios.get(backendUrl + api_ep, {
        method: "get",
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization":`Bearer ${token}`
        },
        data: "",
      });
      return response;
    } catch (error) {
       console.log("Error in sendGetRestRequest:", error);
        return error?.response
      // throw error;
    }
  };

