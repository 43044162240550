import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import LoginReducer from "./Slice/Slice";

const store = configureStore({
  reducer: {
    Login: LoginReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
    }).concat(logger), // Add redux-logger middleware here
});

export default store;
